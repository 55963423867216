import $ from "jquery";

const toggleClaimCheckBox = (claimReason) => {
  let x = document.querySelector(`.${claimReason}-box`);
  if (x.style.display === "block") {
    x.style.display = "none";
    document.querySelector(
      `.${claimReason}-checkbox`
    ).className = `checkbox choice-checkbox ${claimReason}-checkbox`;
    if (claimReason === "order-missing") {
      document.getElementById("claim_missing_order").click();
    } else {
      $(`.${claimReason}`).html("");
    }
  } else {
    if (claimReason !== "order-missing") {
      document.getElementById(`add-${claimReason}`).click();
    }
    x.style.display = "block";
    document.querySelector(`.${claimReason}-checkbox`).className += " checked";
  }
};

const toggleClaimCheckBoxes = () => {
  [
    "packaging",
    "infestation",
    "missing",
    "discount",
    "error",
    "parcel",
    "surplus",
    "callback",
    "order-missing",
    "other",
  ].forEach((claimReason) => {
    $(document).on("click", `#${claimReason}`, function (f) {
      f.preventDefault();
      toggleClaimCheckBox(`${claimReason}`);
    });
  });

  $(document).on("click", "#missing-order", function (e) {
    e.preventDefault();
    if (
      document.querySelector(".missing-order-checkbox").className ===
      "checkbox missing-order-checkbox"
    ) {
      document.querySelector(".missing-order-checkbox").className += " checked";
      document.getElementById("claim_missing_order").click();
    } else {
      document.querySelector(".missing-order-checkbox").className =
        "checkbox missing-order-checkbox";
      document.getElementById("claim_missing_order").click();
    }
  });
  $(document).on("click", "#certify", function (e) {
    e.preventDefault();
    if (
      document.querySelector(".certify-checkbox").className ===
      "checkbox certify-checkbox"
    ) {
      document.querySelector(".certify-checkbox").className += " checked";
    } else {
      document.querySelector(".certify-checkbox").className =
        "checkbox certify-checkbox";
    }
  });

  $(document).on("click", "#other-subreason-list", function (e) {
    e.preventDefault();
    if (document.querySelector("#other-subreason-list").value === "2") {
      console.log("click");
      document.querySelector("#content-line").className =
        "claim-item-line mt-2";
    } else {
      document.querySelector("#content-line").className =
        "claim-item-line mt-2 d-none";
    }
  });
};

export { toggleClaimCheckBoxes };
