require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

import $ from "jquery";
import "cocoon-js";
import "bootstrap";
import "select2/dist/css/select2.css";
import {
  initSelect2,
  initSelect2OnClaim,
  initSelect2ForClientsExtractions,
  initSelect2ForSalesExtractions,
  initSelect2ForDepartmentExtractions,
} from "../components/init_select2";
import {
  clearClientsSearch,
  clearOrdersSearch,
  clearClaimsSearch,
} from "../components/clear_selection";
import { cleanQuery } from "../components/clean_query";
import { initFlatpickr } from "../components/init_flatpickr";
import { updateCityList } from "../components/update_city_list";
import { updateClaimOrder } from "../components/update_claim_order";
import { toggleClaimCheckBoxes } from "../components/toggle_claim_checkboxes";
import { toggleExtractionsCheckBoxes } from "../components/toggle_extractions_checkboxes";
import { displayClaimSubmitButton } from "../components/display_claim_submit_button";
import { updateNotifPreferences } from "../components/update_notif_preferences";
import { adaptDropdownDirection } from "../components/adapt_dropdown_direction";
import { validatePasswordRequest } from "../components/password_modal";
import { extractCsvDatas } from "../components/extract_csv_datas";
import processWarehouseClaimFile from "../components/process_warehouse_claim_file";
// import "../scripts/direct_upload";

global.$ = jQuery;

adaptDropdownDirection();

if (document.querySelector(".select2")) {
  initSelect2();
}

if (document.querySelector(".select2-clients-extractions")) {
  initSelect2ForClientsExtractions();
  initSelect2ForSalesExtractions();
  initSelect2ForDepartmentExtractions();
  toggleExtractionsCheckBoxes();
  document
    .querySelector("#extraction-button")
    .addEventListener("click", extractCsvDatas);
}

if (document.querySelector("#desktop-qc")) {
  clearClientsSearch();
  updateCityList();
}

if (document.querySelector("#desktop-qo")) {
  clearOrdersSearch();
}

if (document.querySelector("#desktop-qr")) {
  clearClaimsSearch();
}

if (document.querySelector("form")) {
  cleanQuery();
}

if (document.querySelector(".date-container")) {
  initFlatpickr();
}

if (document.querySelector(".warehouse-claim-upload")) {
  $("#warehouseClaimUpload").on("change", function () {
    processWarehouseClaimFile();
  });
}

if (document.querySelector(".claim-select")) {
  $("#contactWarningModal").modal({ backdrop: "static", keyboard: false });
  $('#packaging').on('click', function displayPackagingNotice() {
    $('#packagingNoticeModal').modal({ backdrop: "static", keyboard: false });
    $('#packaging').off('click', displayPackagingNotice);
  });
  updateClaimOrder();
  initSelect2OnClaim();
  $("#order_id").val(document.querySelector("#order_id").dataset.id);
  $("#order_id").trigger("change");
  toggleClaimCheckBoxes();
  displayClaimSubmitButton();
  $(document).on("click touchstart", ".checkboxes", displayClaimSubmitButton);
}

if (document.querySelector(".account-container")) {
  updateNotifPreferences();
}

if (document.getElementById("reset-password-button")) {
  document
    .getElementById("reset-password-button")
    .addEventListener("click", validatePasswordRequest);
}
