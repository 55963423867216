import Rails from '@rails/ujs';
import $ from 'jquery';

const updateNotifPreferences = () => {
  $('input[type=checkbox]').change(function () {
    var form = this.parentNode;
    Rails.fire(form, 'submit');
  });
};

export { updateNotifPreferences }
