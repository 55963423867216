import $ from "jquery";

const updateClaimOrder = () => {
  $(function () {
    $("#order_id").on("change", function () {
      window.location.replace("/orders/" + $("#order_id").val() + "/claims/new");
    });
  });
};

export { updateClaimOrder };
