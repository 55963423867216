import $ from 'jquery';

const toggleExtractionsCheckBoxes = () => {
  $(document).on("click", "#clients-extraction", function (e) {
    e.preventDefault();
    let x = document.querySelector(".clients-extraction-box");
    let y = document.querySelector(".sales-extraction-box");
    let z = document.querySelector(".department-extraction-box");
    if (x.style.display === "none") {
      x.style.display = "block";
      y.style.display = "none";
      z.style.display = "none";
      document.querySelector(".clients-extraction-checkbox").className += " checked";
      document.querySelector(".sales-extraction-checkbox").className = "checkbox choice-checkbox sales-extraction-checkbox";
      document.querySelector(".department-extraction-checkbox").className = "checkbox choice-checkbox department-extraction-checkbox";
    } else {
      x.style.display = "none";
      document.querySelector(".clients-extraction-checkbox").className = "checkbox choice-checkbox clients-extraction-checkbox";
    }
  });
  $(document).on("click", "#sales-extraction", function (e) {
    e.preventDefault();
    let x = document.querySelector(".sales-extraction-box");
    let y = document.querySelector(".clients-extraction-box");
    let z = document.querySelector(".department-extraction-box");
    if (x.style.display === "block") {
      x.style.display = "none";
      document.querySelector(".sales-extraction-checkbox").className = "checkbox choice-checkbox sales-extraction-checkbox";
    } else {
      x.style.display = "block";
      y.style.display = "none";
      z.style.display = "none";
      document.querySelector(".sales-extraction-checkbox").className += " checked";
      document.querySelector(".clients-extraction-checkbox").className = "checkbox choice-checkbox clients-extraction-checkbox";
      document.querySelector(".department-extraction-checkbox").className = "checkbox choice-checkbox department-extraction-checkbox";
    }
  });
  $(document).on("click", "#department-extraction", function (e) {
    e.preventDefault();
    let x = document.querySelector(".department-extraction-box");
    let y = document.querySelector(".clients-extraction-box");
    let z = document.querySelector(".sales-extraction-box");
    if (x.style.display === "block") {
      x.style.display = "none";
      document.querySelector(".department-extraction-checkbox").className = "checkbox choice-checkbox department-extraction-checkbox";
    } else {
      x.style.display = "block";
      y.style.display = "none";
      z.style.display = "none";
      document.querySelector(".department-extraction-checkbox").className += " checked";
      document.querySelector(".clients-extraction-checkbox").className = "checkbox choice-checkbox clients-extraction-checkbox";
      document.querySelector(".sales-extraction-checkbox").className = "checkbox choice-checkbox sales-extraction-checkbox";
    }
  });

  $(document).on("click", "#orders-extraction", function (e) {
    e.preventDefault();
    if (document.querySelector(".orders-extraction-checkbox").className === "checkbox choice-checkbox orders-extraction-checkbox") {
      document.querySelector(".orders-extraction-checkbox").className += " checked";
      document.querySelector(".imported-claims-extraction-checkbox").className = "checkbox choice-checkbox imported-claims-extraction-checkbox";
      document.querySelector("#extraction-dates").style.display = "block";
      document.querySelector("#extraction-targets").style.display = "block";
      document.querySelector(".logs-extraction-checkbox").className = "checkbox choice-checkbox logs-extraction-checkbox";
      document.querySelector(".claims-extraction-checkbox").className = "checkbox choice-checkbox claims-extraction-checkbox";
      document.querySelector(".logs-sales-extraction-checkbox").className = "checkbox choice-checkbox logs-sales-extraction-checkbox";
    }
  });
  $(document).on("click", "#claims-extraction", function (e) {
    e.preventDefault();
    if (document.querySelector(".claims-extraction-checkbox").className === "checkbox choice-checkbox claims-extraction-checkbox") {
      document.querySelector(".claims-extraction-checkbox").className += " checked";
      document.querySelector(".orders-extraction-checkbox").className = "checkbox choice-checkbox orders-extraction-checkbox";
      document.querySelector(".imported-claims-extraction-checkbox").className = "checkbox choice-checkbox imported-claims-extraction-checkbox";
      document.querySelector(".logs-extraction-checkbox").className = "checkbox choice-checkbox logs-extraction-checkbox";
      document.querySelector("#extraction-dates").style.display = "block";
      document.querySelector("#extraction-targets").style.display = "block";
      document.querySelector(".logs-sales-extraction-checkbox").className = "checkbox choice-checkbox logs-sales-extraction-checkbox";
    }
  });
  $(document).on("click", "#imported-claims-extraction", function (e) {
    e.preventDefault();
    if (document.querySelector(".imported-claims-extraction-checkbox").className === "checkbox choice-checkbox imported-claims-extraction-checkbox") {
      document.querySelector(".imported-claims-extraction-checkbox").className += " checked";
      document.querySelector(".orders-extraction-checkbox").className = "checkbox choice-checkbox orders-extraction-checkbox";
      document.querySelector("#extraction-dates").style.display = "block";
      document.querySelector("#extraction-targets").style.display = "block";
      document.querySelector(".logs-extraction-checkbox").className = "checkbox choice-checkbox logs-extraction-checkbox";
      document.querySelector(".claims-extraction-checkbox").className = "checkbox choice-checkbox claims-extraction-checkbox";
      document.querySelector(".logs-sales-extraction-checkbox").className = "checkbox choice-checkbox logs-sales-extraction-checkbox";
    }
  });
  $(document).on("click", "#logs-extraction", function (e) {
    e.preventDefault();
    if (document.querySelector(".logs-extraction-checkbox").className === "checkbox choice-checkbox logs-extraction-checkbox") {
      document.querySelector(".logs-extraction-checkbox").className += " checked";
      document.querySelector(".orders-extraction-checkbox").className = "checkbox choice-checkbox orders-extraction-checkbox";
      document.querySelector(".imported-claims-extraction-checkbox").className = "checkbox choice-checkbox imported-claims-extraction-checkbox";
      document.querySelector("#extraction-dates").style.display = "none";
      document.querySelector("#extraction-targets").style.display = "block";
      document.querySelector(".claims-extraction-checkbox").className = "checkbox choice-checkbox claims-extraction-checkbox";
      document.querySelector(".logs-sales-extraction-checkbox").className = "checkbox choice-checkbox logs-sales-extraction-checkbox";
    }
  });
  $(document).on("click", "#logs-sales-extraction", function (e) {
    e.preventDefault();
    if (document.querySelector(".logs-sales-extraction-checkbox").className === "checkbox choice-checkbox logs-sales-extraction-checkbox") {
      document.querySelector(".logs-sales-extraction-checkbox").className += " checked";
      document.querySelector(".orders-extraction-checkbox").className = "checkbox choice-checkbox orders-extraction-checkbox";
      document.querySelector(".imported-claims-extraction-checkbox").className = "checkbox choice-checkbox imported-claims-extraction-checkbox";
      document.querySelector(".claims-extraction-checkbox").className = "checkbox choice-checkbox claims-extraction-checkbox";
      document.querySelector(".logs-extraction-checkbox").className = "checkbox choice-checkbox logs-extraction-checkbox";
      document.querySelector("#extraction-dates").style.display = "none";
      document.querySelector("#extraction-targets").style.display = "none";
    }
  });
};

export { toggleExtractionsCheckBoxes }
