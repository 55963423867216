const displayClaimSubmitButton = () => {
  if (
    document.querySelector(".infestation-checkbox").className ===
      "checkbox choice-checkbox infestation-checkbox" &&
    document.querySelector(".packaging-checkbox").className ===
      "checkbox choice-checkbox packaging-checkbox" &&
    document.querySelector(".missing-checkbox").className ===
      "checkbox choice-checkbox missing-checkbox" &&
    document.querySelector(".discount-checkbox").className ===
      "checkbox choice-checkbox discount-checkbox" &&
    document.querySelector(".error-checkbox").className ===
      "checkbox choice-checkbox error-checkbox" &&
    document.querySelector(".parcel-checkbox").className ===
      "checkbox choice-checkbox parcel-checkbox" &&
    document.querySelector(".surplus-checkbox").className ===
      "checkbox choice-checkbox surplus-checkbox" &&
    document.querySelector(".order-missing-checkbox").className ===
      "checkbox choice-checkbox order-missing-checkbox" &&
    document.querySelector(".callback-checkbox").className ===
      "checkbox choice-checkbox callback-checkbox" &&
    document.querySelector(".other-checkbox").className ===
      "checkbox choice-checkbox other-checkbox"
  ) {
    document.querySelector(".comment-box").style.display = "none";
    document.querySelector(".certify-box").style.display = "none";
  } else {
    document.querySelector(".comment-box").style.display = "block";
    document.querySelector(".certify-box").style.display = "block";
  }
  if (
    document.querySelector(".certify-checkbox").className ===
    "checkbox certify-checkbox"
  ) {
    document.querySelector(".claim-submit-button").className =
      "claim-submit-button d-none";
  } else {
    document.querySelector(".claim-submit-button").className =
      "claim-submit-button";
  }
};

export { displayClaimSubmitButton };
