import $ from 'jquery';

const clearClientsSearch = () => {
  $(".clear").on("click touchstart", function () {
    $("#mobile-qc").val("");
    $("#mobile-dep").val(null).trigger("change");
    $("#mobile-city").val(null).trigger("change");
    $("#desktop-qc").val("");
    $("#desktop-dep").val(null).trigger("change");
    $("#desktop-city").val(null).trigger("change");
  });
};

const clearOrdersSearch = () => {
  $(".clear").on("click touchstart", function () {
    $("#mobile-qo").val("");
    $("#mobile-start-date").flatpickr().clear();
    $("#mobile-end-date").flatpickr().clear();
    $("#mobile-status").val("");
    $("#desktop-qo").val("");
    $("#desktop-start-date").val("");
    $("#desktop-end-date").val("");
    $("#desktop-status").val("");
  });
};

const clearClaimsSearch = () => {
  $(".clear").on("click touchstart", function () {
    $("#mobile-qr").val("");
    $("#mobile-start-date").flatpickr().clear();
    $("#mobile-end-date").flatpickr().clear();
    $("#mobile-claim-status").val("");
    $("#desktop-qr").val("");
    $("#desktop-start-date").val("");
    $("#desktop-end-date").val("");
    $("#desktop-claim-status").val("");
  });
};

export { clearClientsSearch, clearOrdersSearch, clearClaimsSearch };
