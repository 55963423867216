// import { fetchWithToken } from "../utils/fetch_with_token";

const validatePasswordRequest = () => {
  document.querySelector(".login-links").insertAdjacentHTML("afterend", '<div class="modal" tabindex="-1" role="dialog" id="confirmModal"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header"><div></div><h5 class="modal-password-title">Demande enregistrée</h5><button type="button" class="close m-0 p-0" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body"><p>Si votre identifiant correspond à un compte existant au sein de Purmatoo, vous recevrez sous peu un lien de réinitialisation de votre mot de passe sur l\'adresse mail liée à votre compte.</p><p>Si vous ne recevez pas le lien de réinitialisation de votre mot de passe, merci de rentrer en contact avec votre commercial.</p><button onclick="location.href=\'/users/sign_in\';" class="btn-success login-btn mt-2" id="password-back-btn">Revenir à l\'écran de connexion</button></div></div></div></div>');
  $('#confirmModal').modal()
  $(window).keydown(function (event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      document.getElementById("password-back-btn").click();
    }
  });

};

export { validatePasswordRequest };
