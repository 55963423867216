const fetchDates = (data, dataType, wish) => {
  let startDate = document.getElementById("desktop-start-date")._flatpickr.selectedDates[0];
  let endDate = document.getElementById("desktop-end-date")._flatpickr.selectedDates[0];
  let dates = null;
  if (startDate === undefined && endDate === undefined) {
    dates = false;
  } else {
    dates = true;
  }
  $.ajax({
    url: "/extractions.js",
    type: "POST",
    data: { clients_data: data, data_type: dataType, wish: wish, start_date: startDate, end_date: endDate, dates: dates, email: document.querySelector(".extraction-email-field").value }
  });
};

const dataToExtract = (data, dataType) => {
  if (document.querySelector(".orders-extraction-checkbox").className === "checkbox choice-checkbox orders-extraction-checkbox checked") {
    const wish = "orders";
    fetchDates(data, dataType, wish)
  } else if (document.querySelector(".claims-extraction-checkbox").className === "checkbox choice-checkbox claims-extraction-checkbox checked") {
    const wish = "claims";
    fetchDates(data, dataType, wish)
  } else if (document.querySelector(".imported-claims-extraction-checkbox").className === "checkbox choice-checkbox imported-claims-extraction-checkbox checked") {
    const wish = "imported-claims";
    fetchDates(data, dataType, wish)
  } else if (document.querySelector(".logs-extraction-checkbox").className === "checkbox choice-checkbox logs-extraction-checkbox checked") {
    const wish = "logs";
    fetchDates(data, dataType, wish)
  } else {
    const wish = "sales-logs";
    fetchDates(data, dataType, wish)
  }
};

const extractCsvDatas = () => {
  const data = [];
  function iterate(item) {
    let x = item.id;
    data.push(x);
  }
  if (document.querySelector(".clients-extraction-checkbox").className === "checkbox choice-checkbox clients-extraction-checkbox checked") {
    $(".select2-clients-extractions").select2('data').forEach(iterate);
    const dataType = "clients";
    dataToExtract(data, dataType)
  } else if (document.querySelector(".sales-extraction-checkbox").className === "checkbox choice-checkbox sales-extraction-checkbox checked") {
    $(".select2-sales-extractions").select2('data').forEach(iterate);
    const dataType = "sales";
    dataToExtract(data, dataType)
  } else if (document.querySelector(".department-extraction-checkbox").className === "checkbox choice-checkbox department-extraction-checkbox checked") {
    $(".select2-department-extractions").select2('data').forEach(iterate);
    const dataType = "department";
    dataToExtract(data, dataType)
  } else {
    const dataType = "";
    dataToExtract(data, dataType)
  }
};

export { extractCsvDatas }
