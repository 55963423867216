import $ from 'jquery';

const cleanQuery = () => {
  $(document).ready(function () {
    $("form").submit(function () {
      $("input, select").each(function (index, obj) {
        if ($(obj).val() == "") {
          $(obj).remove();
        }
      });
    });
  });
}

export { cleanQuery }
