import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css"

let initFlatpickr = () => {
  let initMobileStartFlatpickr = flatpickr("#mobile-start-date", {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        longhand: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      },
      months: {
        shorthand: ['Jan', 'Fev', 'Mars', 'Av', 'Маi', 'Juin', 'Juilllet', 'Аoût', 'Sep', 'Оct', 'Nov', 'Dec'],
        longhand: ['Janvier', 'Février', 'Маrs', 'Avril', 'Маi', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      },
    },
    dateFormat: "d/m/Y",
    minDate: "01/04/2019",
    maxDate: "today",
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr) {
        initMobileEndFlatpickr.set('minDate', dateStr);
      }
    },
  });
  let initMobileEndFlatpickr = flatpickr("#mobile-end-date", {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        longhand: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      },
      months: {
        shorthand: ['Jan', 'Fev', 'Mars', 'Av', 'Маi', 'Juin', 'Juilllet', 'Аoût', 'Sep', 'Оct', 'Nov', 'Dec'],
        longhand: ['Janvier', 'Février', 'Маrs', 'Avril', 'Маi', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      },
    },
    dateFormat: "d/m/Y",
    minDate: "01/04/2019",
    maxDate: "today",
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr) {
        initMobileStartFlatpickr.set('maxDate', dateStr);
      }
    },
  });
  let initDesktopStartFlatpickr = flatpickr("#desktop-start-date", {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        longhand: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      },
      months: {
        shorthand: ['Jan', 'Fev', 'Mars', 'Av', 'Маi', 'Juin', 'Juilllet', 'Аoût', 'Sep', 'Оct', 'Nov', 'Dec'],
        longhand: ['Janvier', 'Février', 'Маrs', 'Avril', 'Маi', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      },
    },
    dateFormat: "d/m/Y",
    minDate: "01/04/2019",
    maxDate: "today",
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr) {
        initDesktopEndFlatpickr.set('minDate', dateStr);
      }
    },
  });
  let initDesktopEndFlatpickr = flatpickr("#desktop-end-date", {
    "locale": {
      "firstDayOfWeek": 1,
      weekdays: {
        shorthand: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        longhand: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      },
      months: {
        shorthand: ['Jan', 'Fev', 'Mars', 'Av', 'Маi', 'Juin', 'Juilllet', 'Аoût', 'Sep', 'Оct', 'Nov', 'Dec'],
        longhand: ['Janvier', 'Février', 'Маrs', 'Avril', 'Маi', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      },
    },
    dateFormat: "d/m/Y",
    minDate: "01/04/2019",
    maxDate: "today",
    onClose: function (selectedDates, dateStr, instance) {
      if (dateStr) {
        initDesktopStartFlatpickr.set('maxDate', dateStr);
      }
    },
  });
}

export { initFlatpickr }
