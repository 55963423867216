import * as XLSX from "xlsx";
import { parameterizeObjectKeys } from "../lib/parameterize";

const retrieveClaimItemsFromFile = async (file) => {
  const claimItems = [];
  await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      let rows = XLSX.utils.sheet_to_json(sheet);
      rows = rows.map((row) => parameterizeObjectKeys(row));
      rows.forEach((row) => {
        if (
          row["ean"] == null ||
          row["sku"] == null ||
          row["ean"] == "0" ||
          row["sku"] == "0"
        )
          return;
        claimItems.push(row);
      });
      resolve();
    };
    reader.readAsBinaryString(file);
  });
  return claimItems;
};

const processWarehouseClaimFile = async () => {
  const file = document.querySelector("input[type=file]").files[0];
  const claimItemsFromFile = await retrieveClaimItemsFromFile(file);
  if (claimItemsFromFile.length === 0) return;

  const url = new URL(window.location.href);
  $.ajax({
    url: url,
    type: "POST",
    data: { claim_items: claimItemsFromFile },
  });
};

export default processWarehouseClaimFile;
