import $ from 'jquery';

const updateCityList = () => {
  $(function () {
    $("#mobile-dep").on("change", function () {
      $.ajax({
        url: "/cities_by_department",
        type: "GET",
        data: { selected_department: $("#mobile-dep").val() }
      });
    });
    $("#desktop-dep").on("change", function () {
      $.ajax({
        url: "/cities_by_department",
        type: "GET",
        data: { selected_department: $("#desktop-dep").val() }
      });
    });
  });
};

export { updateCityList };
