import $ from 'jquery';
import 'select2';

const initSelect2 = () => {
  $('.select2').select2({
    width: 'element',
    placeholder: "Tous",
    containerCssClass: 'select2-js-container',
    language: {
      noResults: function (params) {
        return "Pas de résultat";
      }
    },
  });
};

const initSelect2OnClaim = () => {
  $(".add_fields").on("click touchstart", function () {
    setTimeout(function () {
      $('.select2-products').select2({
        width: '100%',
        placeholder: "Sélectionner le produit",
        containerCssClass: 'select2-js-container',
        language: {
          noResults: function (params) {
            return "Pas de résultat";
          }
        },
      });
    }, 1);
  });
};

const initSelect2ForClientsExtractions = () => {
  $('.select2-clients-extractions').select2({
    width: 'element',
    placeholder: "Choisissez un ou plusieurs clients",
    closeOnSelect: false,
    allowClear: true,
    multiple: true,
    containerCssClass: 'select2-js-container',
    language: {
      noResults: function (params) {
        return "Pas de résultat";
      }
    },
  });
  $('.select2-clients-extractions').val(null).trigger("change");
};

const initSelect2ForSalesExtractions = () => {
  $('.select2-sales-extractions').select2({
    width: 'element',
    placeholder: "Choisissez un ou plusieurs commerciaux",
    closeOnSelect: false,
    allowClear: true,
    multiple: true,
    containerCssClass: 'select2-js-container',
    language: {
      noResults: function (params) {
        return "Pas de résultat";
      }
    },
  });
  $('.select2-sales-extractions').val(null).trigger("change");
};

const initSelect2ForDepartmentExtractions = () => {
  $('.select2-department-extractions').select2({
    width: 'element',
    placeholder: "Choisissez un ou plusieurs départments",
    closeOnSelect: false,
    allowClear: true,
    multiple: true,
    containerCssClass: 'select2-js-container',
    language: {
      noResults: function (params) {
        return "Pas de résultat";
      }
    },
  });
  $('.select2-department-extractions').val(null).trigger("change");
};

export { initSelect2, initSelect2OnClaim, initSelect2ForClientsExtractions, initSelect2ForSalesExtractions, initSelect2ForDepartmentExtractions };
